/* REACT IMPORTS */
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

/* STYLES */
import "../styles/global.css";

/* LAYOUT */
import Layout from "../layout";

/* PAGES */
import AuthLogin from "../pages/auth/login";
import AuthRegister from "../pages/auth/register";

import Company from "../pages/dashboard/company";

import Members from "../pages/dashboard/members";
import AddMembers from "../pages/dashboard/members/add-member";
import EditMembers from "../pages/dashboard/members/edit-member";
import MemberDetails from "../pages/dashboard/members/view-member";

import ContactUs from "../pages/dashboard/contact-us";
import HelpDesk from "../pages/dashboard/help-desk";
import NewsLetter from "../pages/dashboard/newsLetter";
import RegistrationForm from "../pages/dashboard/registration-form";
import AddCompany from "pages/dashboard/company/add-company";
import Tags from "pages/dashboard/tags";
import Events from "../pages/dashboard/event";
import ViewEvent from "pages/dashboard/event/view-event";
import EditEvent from "pages/dashboard/event/edit-event";
import AddEvent from "pages/dashboard/event/add-event";
import Sponsor from "pages/dashboard/sponsor";
import CompanyDetails from "pages/dashboard/company/view-company";
import EditCompany from "pages/dashboard/company/edit-company";
import RsvpList from "pages/dashboard/rsvp";
import RsvpDetails from "pages/dashboard/rsvp/rsvp-details";
import SponsorRequestList from "pages/dashboard/sponsor-request";
import SponsorRequestDetails from "pages/dashboard/sponsor-request/details";

/* SCROLL TO TOP */
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const AppRoutes = () => {
  return (
    <div className="app">
      <Router>
        <ScrollToTop />
        <Routes>
          {/* LOGIN AND REGISTER */}
          <Route path="/" element={<AuthLogin />} />
          <Route path="/register" element={<AuthRegister />} />

          {/* COMPANY */}
          <Route
            path="/company"
            element={
              <Layout>
                <Company />
              </Layout>
            }
          />
          <Route
            path="/company/view-company/:id"
            element={
              <Layout>
                <CompanyDetails />
              </Layout>
            }
          />
          <Route
            path="/company/edit-company/:id"
            element={
              <Layout>
                <EditCompany />
              </Layout>
            }
          />
          <Route
            path="/company/add-company"
            element={
              <Layout>
                <AddCompany />
              </Layout>
            }
          />

          {/* MEMBERS */}
          <Route
            path="/members"
            element={
              <Layout>
                <Members />
              </Layout>
            }
          />
          <Route
            path="/member/view-member/:id"
            element={
              <Layout>
                <MemberDetails />
              </Layout>
            }
          />
          <Route
            path="/member/edit-member/:id"
            element={
              <Layout>
                <EditMembers />
              </Layout>
            }
          />
          <Route
            path="/member/add-member"
            element={
              <Layout>
                <AddMembers />
              </Layout>
            }
          />

          {/* EVENT */}
          <Route
            path="/event"
            element={
              <Layout>
                <Events />
              </Layout>
            }
          />
          <Route
            path="/event/view-event/:id"
            element={
              <Layout>
                <ViewEvent />
              </Layout>
            }
          />
          <Route
            path="/event/edit-event/:id"
            element={
              <Layout>
                <EditEvent />
              </Layout>
            }
          />
          <Route
            path="/event/add-event"
            element={
              <Layout>
                <AddEvent />
              </Layout>
            }
          />

          {/* CONTACT US */}
          <Route
            path="/contact-us"
            element={
              <Layout>
                <ContactUs />
              </Layout>
            }
          />

          {/* HELP DESK */}
          <Route
            path="/help-desk"
            element={
              <Layout>
                <HelpDesk />
              </Layout>
            }
          />

          {/* CONTACT US */}
          <Route
            path="/registration-form"
            element={
              <Layout>
                <RegistrationForm />
              </Layout>
            }
          />

          {/* SECTOR */}
          <Route
            path="/sector"
            element={
              <Layout>
                <Tags />
              </Layout>
            }
          />

          {/* SPONSOR */}
          <Route
            path="/sponsor"
            element={
              <Layout>
                <Sponsor />
              </Layout>
            }
          />

          {/* NEWSLETTER */}
          <Route
            path="/news-letter"
            element={
              <Layout>
                <NewsLetter />
              </Layout>
            }
          />

          {/* RSVP LIST */}
          <Route
            path="/rsvp-list/:eventId"
            element={
              <Layout>
                <RsvpList />
              </Layout>
            }
          />

          <Route
            path="/rsvp-details/:id"
            element={
              <Layout>
                <RsvpDetails />
              </Layout>
            }
          />

          {/* SPONSOR REQUEST LIST */}
          <Route
            path="/sponsor-request/:eventId"
            element={
              <Layout>
                <SponsorRequestList />
              </Layout>
            }
          />

          <Route
            path="/sponsor-request-details/:id"
            element={
              <Layout>
                <SponsorRequestDetails />
              </Layout>
            }
          />
        </Routes>
      </Router>
    </div>
  );
};

export default AppRoutes;
