/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from "react";

import { Helmet } from "react-helmet";

import DataTable from "react-data-table-component";
import Input from "../../../components/input";

import Select from "react-select";

import { DateRangePicker } from "react-date-range";

/* MATERIAL TAILWIND COMPONENTS */
import { Button, Dialog, DialogBody, Tooltip } from "@material-tailwind/react";

/* ICONS */
import { FiTrash } from "react-icons/fi";
import { IoClose, IoEyeSharp } from "react-icons/io5";

/* TOAST NOTIFICATION */
import { toast } from "react-toast";

/* API IMPORT */
import { deleteRsvpDetailsById, getRsvpDetails } from "service/api";
import { Link, useParams } from "react-router-dom";

import ExcelExport from "../../../components/export/Export";
import moment from "moment";

const filterByStatus = [
  { value: null, label: "All" },
  { value: "pending", label: "Pending" },
  { value: "cancelled", label: "Cancelled" },
  { value: "success", label: "Success" },
];

/* CLICK OUTSIDE HANDLER */
const useClickOutside = (handler) => {
  let domNode = useRef();

  useEffect(() => {
    let maybeHandler = (event) => {
      if (!domNode.current?.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener("mousedown", maybeHandler);
    return () => {
      document.removeEventListener("mousedown", maybeHandler);
    };
  });

  return domNode;
};

const RsvpList = () => {
  const { id } = useParams();
  const { eventId } = useParams();
  // STATE VARIABLES
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(50);

  const [rsvpList, setRsvpList] = useState([]);

  const [exportData, setExportData] = useState([]);

  const [totalDocs, setTotalDocs] = useState([]);

  const [search, setSearch] = useState("");

  const [status, setStatus] = useState(null);

  const [selectedList, setSelectedList] = useState([]);

  const [updateAllModal, setUpdateAllModal] = useState(false);
  const [updateAllStatus, setUpdateAllStatus] = useState(null);

  const [deleteId, setDeleteId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);

  const [viewModal, setViewModal] = useState(false);
  const [selectedRsvp, setSelectedRsvp] = useState(null);

  const [datepicker, setDatepicker] = useState(false);

  const [state, setState] = useState([
    {
      startDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth() - 6,
        1
      ),
      endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
      key: "selection",
      color: "#ef4444",
    },
  ]);

  /* FUNCTIONS */
  const handlePageChange = async (e) => {
    console.log("e", e);
    setPage(e);
    getRsvpListFunction();
  };

  const handlePerPageChange = async (e) => {
    setSizePerPage(e);
    getRsvpListFunction();
  };

  const getRsvpListFunction = () => {
    setLoading(true);
    setRsvpList([]);
    const params = {
      page: page,
      sizePerPage: sizePerPage,
      pagination: false,
    };

    if (search) {
      params.search = search;
    }

    if (status !== null) {
      params.status = status?.value;
    }

    if (state?.[0]?.startDate && state?.[0]?.endDate) {
      params.startDate = state?.[0]?.startDate;
      params.endDate = state?.[0]?.endDate;
    }

    getRsvpDetails(eventId, params)
      .then((res) => {
        console.log("%c Line:74 🌰 res", "color:#93c0a4", res);
        setRsvpList(res?.data);
        setTotalDocs(res?.data?.length);

        const tempArray = res?.data?.map((item) => {
          // Simplify address and billing address logic
          console.log("NEXT");
          console.log(
            "item?.personalDetails?.address",
            item?.personalDetails?.address
          );
          console.log(
            "item?.companyDetails?.billingAddress",
            item?.companyDetails?.billingAddress
          );
          const getAddress = (addressObj) => {
            if (!addressObj) return "-";
            const { address, city, state, zipCode, country } = addressObj;
            return [address, city, state, zipCode, country]
              .filter(Boolean)
              .join(", ");
          };

          const getPaymentSummary = (summary, currency) => {
            if (!summary) return "-";
            let content = "";
            summary.forEach((item, index) => {
              index === 0
                ? (content += `${item?.type} : ${item.price} ${currency} `)
                : (content += `, ${item?.type} : ${item.price} ${currency} `);
            });
            return content;
          };

          return {
            "Invoice ID": item?.invoiceId?.invoiceId || "-",
            "First Name": item?.personalDetails?.userId?.firstName || "-",
            "Last Name": item?.personalDetails?.userId?.lastName || "-",
            Email: item?.personalDetails?.userId?.email || "-",
            "Phone Number": item?.personalDetails?.userId?.phoneNumber || "-",
            "Company Name": item?.companyDetails?.name || "-",
            "Amount Paid": item?.currency
              ? `${item?.currency} ${item?.amount}`
              : `INR ${item?.amount}`,
            "Payment Breakdown": item?.paymentSummary
              ? getPaymentSummary(item?.paymentSummary, item?.currency)
              : "-",
            Status: item?.paymentStatus || "-",
            Address: getAddress(item?.personalDetails?.address),
            "Billing Address": getAddress(
              item?.companyDetails?.billingAddress === undefined ||
                item?.companyDetails?.billingAddress?.address === ""
                ? item?.personalDetails?.address
                : item?.companyDetails?.billingAddress
            ),
            Date: new Date(item?.createdAt).toLocaleDateString("en-gb", {
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: true,
            }),
          };
        });

        console.log("tempArray", tempArray);
        setExportData(tempArray);
      })
      .catch((e) => {
        console.log("e-->", e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /* HANDLE DELETE DATA */
  const handleDelete = () => {
    deleteRsvpDetailsById(deleteId)
      .then((res) => {
        toast.success(res?.message);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally(() => {
        setDeleteModal(!deleteModal);
        setDeleteId(null);
        getRsvpListFunction();
      });
  };

  const caseInsensitiveSortForName = (rowA, rowB) => {
    const a = rowA?.firstName?.toLowerCase();
    const b = rowB?.lastName?.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  const caseInsensitiveSortForCompany = (rowA, rowB) => {
    const a = rowA?.companyDetails?.name?.toLowerCase();
    const b = rowB?.companyDetails?.name?.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  const caseInsensitiveSortForRole = (rowA, rowB) => {
    const a = rowA?.personalDetails?.userId?.role?.name?.toLowerCase();
    const b = rowB?.personalDetails?.userId?.role?.name?.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  const caseInsensitiveSortForStatus = (rowA, rowB) => {
    const a = rowA.paymentStatus;
    const b = rowB.paymentStatus;

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  const caseInsensitiveSortForDateAndTime = (rowA, rowB) => {
    const a = rowA.createdAt.toLowerCase();
    const b = rowB.createdAt.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  const caseInsensitiveSortForInvoiceId = (rowA, rowB) => {
    const a = rowA?.invoiceId?.invoiceId?.toLowerCase();
    const b = rowB?.invoiceId?.invoiceId?.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  /* COLUMNS */
  const columns = [
    {
      name: "Sr. No",
      grow: 0.1,
      selector: (row, index) => (
        <span className="text-sm font-ibm-semibold text-black text-opacity-60">
          {index + 1}
        </span>
      ),
    },
    {
      name: "Invoice Number",
      grow: 1,
      selector: (row) => (
        <p className="text-sm font-ibm-regular text-greyText hover:text-logoYellow">
          {row?.invoiceId?.invoiceId ? row?.invoiceId?.invoiceId : "-"}
        </p>
      ),
      sortable: true,
      sortFunction: caseInsensitiveSortForInvoiceId,
    },
    {
      name: "Name and Email",
      grow: 2,
      selector: (row) => (
        <div>
          <p className="text-sm font-ibm-semibold text-black">
            {`${row?.personalDetails?.userId?.firstName} ${row?.personalDetails?.userId?.lastName}`}
          </p>
          <a
            href={`mailto:${row?.personalDetails?.userId?.email}`}
            className="text-sm font-ibm-regular text-greyText hover:text-logoYellow"
          >
            {row?.personalDetails?.userId?.email}
          </a>
        </div>
      ),
      sortable: true,
      sortFunction: caseInsensitiveSortForName,
    },
    {
      name: "Phone",
      grow: 1,
      selector: (row) => (
        <a
          href={`tel:${row?.personalDetails?.userId?.phoneNumber}`}
          className="text-sm font-ibm-regular text-greyText hover:text-logoYellow"
        >
          {row?.personalDetails?.userId?.phoneNumber}
        </a>
      ),
    },
    {
      name: "Company",
      grow: 1,
      selector: (row) => (
        <span className="text-sm font-ibm-regular text-greyText">
          {row?.companyDetails?.name}
        </span>
      ),
      sortable: true,
      sortFunction: caseInsensitiveSortForCompany,
    },
    {
      name: "Attending As",
      grow: 1,
      selector: (row) => (
        <span className="text-sm font-ibm-bold text-black capitalize">
          {row?.personalDetails?.userId?.role?.name
            ? row?.personalDetails?.userId?.role?.name
            : "-"}
        </span>
      ),
      sortable: true,
      sortFunction: caseInsensitiveSortForRole,
    },
    {
      name: "Status",
      grow: 0,
      selector: (row) => (
        <span
          className={`text-sm font-ibm-bold text-black capitalize ${
            row?.paymentStatus === "success"
              ? "text-green-600"
              : "text-orange-600"
          }`}
        >
          {row?.paymentStatus}
        </span>
      ),
      sortable: true,
      sortFunction: caseInsensitiveSortForStatus,
    },
    {
      name: "Date & Time",
      selector: (row) => (
        <>
          <p className="text-sm font-ibm-regular text-greyText">
            {new Date(row.createdAt).toLocaleDateString("en-gb", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </p>
          <p className="mt-1 text-sm font-ibm-regular text-greyText">
            {new Date(row.createdAt).toLocaleTimeString("en-gb", {
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: true,
            })}
          </p>
        </>
      ),
      sortable: true,
      sortFunction: caseInsensitiveSortForDateAndTime,
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="flex items-center">
          <Tooltip content="View" className="text-sm font-ibm-semibold">
            <Link to={"/rsvp-details/" + row?._id}>
              <Button className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5">
                <IoEyeSharp className="w-5 h-5 text-greyText" />
              </Button>
            </Link>
          </Tooltip>
          <Tooltip content="Remove" className="text-sm font-ibm-semibold">
            <Button
              onClick={() => {
                setDeleteId(row?._id);
                setDeleteModal(!deleteModal);
              }}
              className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5"
            >
              <FiTrash className="w-4 h-4 text-greyText" />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  /* LOADING SCREEN FOR DATATABLE */
  const LoadingScreen = () => {
    return (
      <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5">
        <p className="text-sm font-ibm-semibold text-black text-center">
          Please wait!
        </p>
        <p className="text-sm font-ibm-semibold text-black text-center">
          We are fetching data{" "}
        </p>
      </div>
    );
  };

  const NoDataScreen = () => {
    return (
      <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5">
        <p className="text-sm font-ibm-semibold text-black text-center">
          There are no queries to display.
        </p>
      </div>
    );
  };

  /* DOM NODE */
  let domNode = useClickOutside(() => {
    setDatepicker(false);
  });

  /* USE-EFFECTS */
  useEffect(() => {
    getRsvpListFunction();
  }, [page, sizePerPage, search, eventId, status, state]);

  return (
    <section className="px-0 py-2 xl:p-5 lg:p-5 min-h-screen xl:min-h-full lg:min-h-full">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Rsvp List | LIBF</title>
      </Helmet>

      {/* Adjusted Width */}
      <div className="container-full-width">
        {/* HEADING */}
        <div className="block xl:flex lg:flex items-center justify-between">
          <div>
            <h1 className="text-2xl font-ibm-bold text-black tracking-tight">
              Registrations' List
            </h1>
            <h2 className="text-base font-ibm-regular text-greyText">
              Glance through the registrations made through the app for LIBF GCC
              Calling 2025
            </h2>
          </div>
          {/* SEARCHBAR */}
          <div className="block xl:flex lg:flex items-center gap-2 mt-3 xl:mt-0 lg:mt-0">
            {/* DATE RANGE FILTER */}
            <div className="relative">
              <div ref={domNode}>
                <input
                  type="text"
                  name="dateRange"
                  placeholder="Filter by date range"
                  value={
                    state?.[0]?.startDate && state?.[0]?.endDate
                      ? ` ${state?.[0]?.startDate &&
                          moment(state?.[0]?.startDate).format(
                            "DD/MM/YYYY"
                          )} - ${state?.[0]?.endDate &&
                          moment(state?.[0]?.endDate).format("DD/MM/YYYY")}`
                      : "Select Date"
                  }
                  onClick={() => setDatepicker(!datepicker)}
                  className={`w-full xl:w-[25vh] lg:w-[25vh] bg-white outline-none font-ibm-regular text-${
                    state?.[0]?.startDate && state?.[0]?.endDate
                      ? "text-black"
                      : " text-gray-400"
                  } text-sm focus:outline-none border border-greyBorder rounded-md px-5 py-3`}
                />
              </div>
              {datepicker && (
                <div
                  ref={domNode}
                  className="absolute top-12 bottom-0 transition-all duration-300 ease-linear z-50"
                >
                  <DateRangePicker
                    dragSelectionEnabled
                    showPreview
                    onChange={(item) => setState([item.selection])}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={true}
                    months={1}
                    ranges={state}
                    direction="horizontal"
                    preventSnapRefocus={true}
                    calendarFocus="backwards"
                    className="shadow-xl font-ibm-regular rounded-xl border border-greyBorder cursor-pointer"
                  />
                </div>
              )}
            </div>

            {/* FILTER BY TYPE */}
            <div className="w-full xl:w-[15vh] lg:w-[15vh] flex-shrink-0 mt-2 xl:mt-0 lg:mt-0">
              <Select
                options={filterByStatus}
                className="react-select font-proxima-regular"
                classNamePrefix="filter"
                placeholder="Status"
                name="status"
                value={status}
                onChange={(e) => setStatus(e)}
              />
            </div>

            {/* SEARCHBAR */}
            <div className="w-full xl:w-full lg:w-full mt-2 xl:mt-0 lg:mt-0">
              <Input
                type="text"
                placeholder="Search..."
                name="search"
                id="search"
                value={search}
                onChange={(e) => setSearch(e?.target?.value)}
              />
            </div>

            {/* UPDATE ALL BUTTON */}
            {selectedList?.length > 0 && (
              <Button
                onClick={() => setUpdateAllModal(!updateAllModal)}
                type="button"
                className="w-full text-base shadow-none hover:shadow-none  hover:bg-opacity-80 px-5 py-3 rounded-md font-ibm-semibold text-white transition-all duration-300 ease-in-out normal-case"
              >
                Update All
              </Button>
            )}

            <ExcelExport
              data={exportData}
              content={"Export"}
              fileName="bulkExport"
            />
          </div>
        </div>

        <div className="mt-5">
          <DataTable
            className="data-table"
            columns={columns}
            data={totalDocs > 0 ? rsvpList : []}
            progressPending={loading}
            progressComponent={<LoadingScreen />}
            pagination
            // paginationServer
            paginationTotalRows={totalDocs}
            onChangeRowsPerPage={handlePerPageChange}
            onChangePage={handlePageChange}
            noDataComponent={<NoDataScreen />}
            rowsPerPage={sizePerPage}
            paginationRowsPerPageOptions={[50, 100]}
            paginationPerPage={sizePerPage}
            defaultSortFieldId={1}
          />
        </div>
        {/* Delete modal */}
        <Dialog
          open={deleteModal}
          handler={() => setDeleteModal(!deleteModal)}
          size="xs"
          animate={{
            mount: { scale: 1, y: 0 },
            unmount: { scale: 0.9, y: -100 },
          }}
        >
          <DialogBody className="p-0">
            <div className="border-b border-authBorder px-5 py-3 flex items-center justify-between">
              <h5 className="text-lg font-ibm-bold text-black tracking-tight">
                Delete List
              </h5>
              <Button
                className="p-2 rounded-full bg-white shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5"
                onClick={() => setDeleteModal(!deleteModal)}
              >
                <IoClose className="w-4 h-4 text-greyText" />
              </Button>
            </div>
            <div className="p-5 border-b border-authBorder">
              <p className="text-base font-ibm-regular text-black">
                Are you sure you want to delete this list?
              </p>
            </div>
            <div className="flex items-center justify-end px-5 py-3">
              <Button
                onClick={handleDelete}
                className="text-sm shadow-none hover:shadow-none bg-logoYellow hover:bg-opacity-80 px-5 py-3 rounded-md font-ibm-semibold text-white transition-all duration-300 ease-in-out normal-case"
              >
                Delete
              </Button>
            </div>
          </DialogBody>
        </Dialog>

        {/* View modal */}
        <Dialog
          open={viewModal}
          handler={() => setViewModal(!viewModal)}
          size="xs"
          animate={{
            mount: { scale: 1, y: 0 },
            unmount: { scale: 0.9, y: -100 },
          }}
        >
          <DialogBody className="p-0">
            {/* Dialog Header */}
            <div className="border-b border-gray-200 px-5 py-3 flex items-center justify-between bg-white">
              <h5 className="text-lg font-ibm-semibold text-gray-800 tracking-tight">
                View Contact Details
              </h5>
              <Button
                className="p-2 rounded-full bg-white shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5"
                onClick={() => setViewModal(!viewModal)}
              >
                <IoClose className="w-4 h-4 text-greyText" />
              </Button>
            </div>

            {/* Dialog Body */}
            {/* <div className="p-5 border-b border-gray-200 bg-white">
              {selectedContact && (
                <div className="space-y-4">
                  <p className="text-sm font-ibm-semibold ">
                    <strong>Name:</strong> {selectedContact.firstName}{" "}
                    {selectedContact.lastName}
                  </p>
                  <p className="text-sm font-ibm-semibold ">
                    <strong>Email:</strong> {selectedContact.email}
                  </p>
                  <p className="text-sm font-ibm-semibold ">
                    <strong>Message:</strong> {selectedContact.message}
                  </p>
                  <p className="text-sm font-ibm-semibold ">
                    <strong>Date & Time:</strong>{" "}
                    {moment(selectedContact.createdAt).format(
                      "MMM DD, YYYY HH:MM A"
                    )}
                  </p>
                </div>
              )}
            </div> */}
          </DialogBody>
        </Dialog>
      </div>
    </section>
  );
};

export default RsvpList;
